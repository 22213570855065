<template>
  <CEESAR-ModelTable 
    title = "Categories"
    label = "Category"
    model = "category"
    fileRoute = "categories/file"
  />
</template>

<script>
export default {
  name:"CategoryTable"
}
</script>