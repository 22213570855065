<template>
    <CEESAR-View title="Ranking">
      <CEESAR-Table>
        <template slot="Filters">
          <CEESAR-ComboBox label="Site" :entries="filterSites" :horizontal="true"
                         v-on:change="OnSiteChanged" :value="filterSite"/>
        </template>

        <CEESAR-TableLine v-for="entity in entities" :key="entity.id">
          <div class="line-ScenarioRanking">
            <div class="line-ScenarioRanking-Description">
              {{entity.internal_id}} - {{entity.description}}<br>
            </div>
            <div class="line-ScenarioRanking-Rating">
              Rating: {{entity.skill_rating}} - Uncertainty: {{entity.skill_uncertainty}} - Nb played game(s): {{entity.nb_games}}
            </div>
          </div>
        </CEESAR-TableLine>

        <template slot="Buttons">
          <CEESAR-Button class="ResetButton" label="Reset Rankings for this Site" icon="delete_sweep" v-on:click="AskResetConfirmation()"/>
        </template>
      </CEESAR-Table>

    </CEESAR-View>
</template>

<script>
export default {
  name:"ScenariosRanking",
  data(){
    return{
      entities: [],
      
      filterSites: [], filterSite: 0
    };
  },
  async mounted(){
    await this.LoadFilterCombos();
    await this.LoadEntities();
  },
  methods:{
    async LoadEntities(){
      this.entities = await this.$ceesar.backend.GetModelAsync("scenario", "get_ranking", {site_id: this.filterSite});
    },
    async LoadFilterCombos(){
      this.filterSites = await this.LoadFilterCombo("site");
      this.filterSite = this.filterSites[0].id;
    },
    async LoadFilterCombo(pModel){
      let unavailable = [];
      unavailable.push({id: -1, label: "<Unavailable>"});

      let entities = await this.$ceesar.backend.GetModelAsync(pModel, "get_all");
      if (entities == null || entities.length < 1)
      {
        this.allFiltersAvailable  = false;
        return unavailable;
      }

      let items = [];
      entities.sort((a, b) => a.label.localeCompare(b.label));
      entities.forEach(entity =>{
          items.push({id: entity.id, label: entity.label});
      });

      return items;
    },

    async OnSiteChanged(pValue){
      this.filterSite = pValue,
      this.LoadEntities();
    },
    async AskResetConfirmation()
    {
        this.$ceesar.design.registerDialogEvent("Answer", (pAnswer) => this.confirmReset(pAnswer));

        this.$ceesar.design.showConfirm(
          "By reseting the rankings, all matches for this site will be deleted. Do you want to export them before reset?",
          [
            {label: "Yes", value: 1, icon:"check"},
            {label: "No", value: 0, icon:"close"}, 
            {label: "Cancel", value: -1, icon:"backspace"},
          ]
        );
    },
    async confirmReset(pDialogAnswer, pId)
    {
      this.$ceesar.design.unregisterDialogEvent("Answer");
      this.$ceesar.design.showToast("Answer = " + pDialogAnswer);
    },
  }
}
</script>

<style lang="less">

@color-blue: rgb(25, 75, 104);
@color-green: rgb(70, 173, 70);

.line-ScenarioRanking{
  padding: 10px 15px;
  width: 100%;

  .line-ScenarioRanking-Description{
    color: @color-green;
    font-weight: bold;
  }

  .line-ScenarioRanking-Rating{
    margin-left: 15px;
  }
}


</style>