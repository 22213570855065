<template>
  <CEESAR-Application>

    <CEESAR-Header>
      <template slot="Left">
        <CEESAR-ApplicationTitle title="Scenario Editor" icon="edit"/>
      </template>

      <template slot="Middle">
        <CEESAR-MenuItem label="Scenarios" icon="movie" route="/scenarios"/>
        <CEESAR-MenuItem label="Tables" icon="table_rows" route = "/tables"/>
        <CEESAR-MenuItem label="Assets" icon="image" route = "/assets"/>
      </template>

      <template slot="Right">
        <CEESAR-Auth-UserDropDown contentAlign="Right"/>
      </template>
    </CEESAR-Header>

    <CEESAR-Main>
      
      <transition name="ViewRouter">
        <router-view />
      </transition>

    </CEESAR-Main>

    <CEESAR-Footer>
      <template slot="Left">
        <CEESAR-ApplicationLogo :url="portalUrl"/>
      </template>
    </CEESAR-Footer>
  </CEESAR-Application>
</template>

<script>
export default {
  name:"Application",
  mounted(){
    this.$ceesar.design.applyTheme(document.querySelector('html'), "ceesar");
  },
  computed:{
    withKeyCloak(){
      return process.env.NODE_ENV != "production";
    },
    portalUrl(){
      return this.$ceesar.router.getApplicationUrl(process.env.VUE_APP_KEYCLOAK_CLIENTID_PORTAL);
    }
  }
}
</script>

<style lang="less">

</style>
