<template>
  <CEESAR-ModelTable 
    title = "Infrastructures"
    label = "Infrastructure"
    model = "infrastructure"
    fileRoute = "infrastructures/file"
  />
</template>

<script>
export default {
  name:"InfrastructureTable"
}
</script>