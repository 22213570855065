<template>
  <CEESAR-ModelTable 
    title = "Road Users"
    label = "Road User"
    model = "road_user"
    fileRoute = "roadusers/file"
  />
</template>

<script>
export default {
  name:"RoadUserTable"
}
</script>