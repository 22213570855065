<template>
  <CEESAR-ModelTable 
    title = "Sites"
    label = "Site"
    model = "site"
    fileRoute = "sites/file"
  />
</template>

<script>
export default {
  name:"SiteTable"
}
</script>