<template>
    <CEESAR-Form :title="id==undefined ? 'New Category' : 'Category Modification '">
        <template slot="Content">
            <CEESAR-TextInputBox label="Label" v-model="entityLabel" :required="true"/>
        </template>    
        
        <template slot="Buttons">
            <CEESAR-Button label="Save" v-on:click="OnSave"/>
            <CEESAR-Button label="Cancel" v-on:click="OnCancel"/>
        </template>
    </CEESAR-Form>
</template>

<script>
export default {
    name: "CategoryFile",
    props: {
        id: {default: undefined}
    },
    data(){
        return {
            entityLabel: ""
        }
    },
    async mounted(){
        if(this.id != undefined)
        {
            let entity = await this.$ceesar.backend.GetModelByIdAsync("category", this.id);
            this.entityLabel = entity.label;
        }
    },
    methods:{
        OnCancel()
        {
            this.$router.go(-1);
        },
        async OnSave()
        {
            if(this.entityLabel.trim() == "")
            {
                this.$ceesar.design.showToast("Label is mandatory.", "error");
                return;
            }

            if(this.id == undefined)
            {
                let entity = { label: this.entityLabel };
                let res = await this.$ceesar.backend.PostModelAsync("category", entity);
                if(res.code < 0)
                {
                    this.$ceesar.design.showToast(res.message, "error");
                    return;
                }
            }
            else
            {
                let entity = {id: this.id, label: this.entityLabel};
                let res = await this.$ceesar.backend.PutModelAsync("category", entity);
                if(res.code < 0)
                {
                    this.$ceesar.design.showToast(res.message, "error");
                    return;
                }
            }

            this.$router.go(-1);
        }
    }
}
</script>

<style lang="less">
</style>