<template>
  <CEESAR-View title="Scenarios">
    <template slot="Menu">
      <CEESAR-MenuItem label="Edition" icon="edit" route="/scenarios/edition"/>
      <CEESAR-MenuItem label="Ranking" icon="thumbs_up_down" route="/scenarios/ranking"/>
      <CEESAR-MenuItem label="Matches" icon="sports_mma" route="/scenarios/matches"/>
    </template>

    <transition name="TableRouter">
      <router-view />
    </transition>

  </CEESAR-View>
</template>

<script>
export default {
  name: "Scenarios"
}
</script>

<style>

</style>