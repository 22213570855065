<template>
    <CEESAR-Form :title="fileTitle" v-if="isLoaded">
            
        <template slot="Content">
            <CEESAR-TextInputBox label="Internal ID" v-model="entityInternalId" :required="true"/>
            <CEESAR-TextInputBox label="Description" v-model="entityDescription" :required="true"/>

            <CEESAR-ComboBox label="Pictogram" 
                :entries="pictograms" :required="true"
                v-on:change="OnPictogramChanged" :value="entityAsset_Id">
            </CEESAR-ComboBox>

            <img class="Pictogram" v-if="showPictogram" v-bind:src="entityPictogramUrl" alt="No selected pictogram"/>
            <CEESAR-FileUpload class="FileUpload-Pictogram"
                v-if="!showPictogram"
                inputId="scenario_pictogram" label="Pictogram" 
                :buttonLabel="selectedFileName" v-model="entityFile" :required="true" />
            
            <CEESAR-CheckBox label="Categories"
                :entries="categories" required="true" v-on:changed="CategoryLinkChanged"/>

            <CEESAR-CheckBox label="Other Road Users"
                :entries="roadUsers" required="true" v-on:changed="RoadUserLinkChanged"/>

            <CEESAR-CheckBox label="Infrastructure"
                :entries="infrastructures" required="true" v-on:changed="InfrastructureLinkChanged"/>

            <CEESAR-CheckBox label="Other Road Users Trajectory"
                :entries="othersTrajectories" required="true" v-on:changed="OthersTrajectoryLinkChanged"/>

            <CEESAR-CheckBox label="AV Trajectory"
                :entries="avTrajectories" required="true" v-on:changed="AvTrajectoryLinkChanged"/>

            <CEESAR-CheckBox label="Operation Site"
                :entries="sites" required="true" v-on:changed="SiteLinkChanged"/>
        </template>

        <template slot="Buttons">
            <CEESAR-Button label="Preview" icon="preview" v-on:click="OnPreview"/>
            <CEESAR-Button label="Save" icon="save" v-on:click="OnSave"/>
            <CEESAR-Button label="Cancel" icon="backspace" v-on:click="OnCancel"/>
        </template>

        <template slot="Dialogs">
            <CEESAR-Dialog  id="ScenarioPreview"
                            title="Scenario Preview">
                <CEESAR-ScenarioCard :scenario="entityPreview"/>
            </CEESAR-Dialog>
        </template>

    </CEESAR-Form>
</template>

<script>

export default {
    name: "ScenarioFile",
    props: {
        id: {default: undefined}
    },
    data(){
        return {
            entityInternalId: "",
            entityDescription: "",
            entityAsset_Id: undefined,

            entityFile: "",

            pictograms: [],
            entityPictogramUrl: "",

            categories: [],
            roadUsers: [],
            infrastructures: [],
            othersTrajectories: [],
            avTrajectories: [],
            sites: [],

            isLoaded: false,

            entityPreview: undefined
        }
    },
    async mounted()
    {
        await this.loadEntity();
        this.isLoaded = true;
    },
    computed:{
        fileTitle(){
            if(this.id == undefined || this.id == "duplicate") return "New Scenario";
            return "Scenario Modification";
            
        },
        selectedFileName(){
            if(this.entityFile)
            {
                let name = this.entityFile.split("\\").pop();
                return name;
            }
            return "Select a file to upload";
        },
        showPictogram(){
            return this.entityAsset_Id > 0;
        }
    },
    methods:{
        async loadEntity(){
            // Filtres sur type a ajouter
            let assets = await this.$ceesar.backend.GetModelAsync("asset");

            this.pictograms.push({id: 0, label: "New Pictogram"});
            assets.forEach(asset => {
                this.pictograms.push({id: asset.id, label: asset.file_name});
            });

            let entity = undefined;
            let effectiveId = this.id;
            if(effectiveId == "duplicate")
            {
                entity = await this.$ceesar.getTransmitData("scenario");
                if (entity == undefined)
                    effectiveId = undefined;
            }

            if(effectiveId != undefined)
            {
                if(entity == undefined)
                    entity = await this.$ceesar.backend.GetModelByIdAsync("scenario", this.id);

                this.entityInternalId = entity.internal_id;
                this.entityDescription = entity.description;
                this.entityAsset_Id = entity.asset_id;

                this.categories = await this.AddLinkCheck("category", entity.category_Ids);
                this.roadUsers = await this.AddLinkCheck("road_user", entity.road_user_Ids);
                this.infrastructures = await this.AddLinkCheck("infrastructure", entity.infrastructure_Ids);
                this.othersTrajectories = await this.AddLinkCheck("trajectory", entity.road_user_trajectory_Ids);
                this.avTrajectories = await this.AddLinkCheck("trajectory", entity.av_trajectory_Ids);
                this.sites = await this.AddLinkCheck("site", entity.site_Ids);
            }
            else
            {
                this.categories = await this.AddLinkCheck("category");
                this.roadUsers = await this.AddLinkCheck("road_user");
                this.infrastructures = await this.AddLinkCheck("infrastructure");
                this.othersTrajectories = await this.AddLinkCheck("trajectory");
                this.avTrajectories = await this.AddLinkCheck("trajectory");
                this.sites = await this.AddLinkCheck("site");
            }

            if(this.entityAsset_Id == undefined) this.entityAsset_Id = 0;
            await this.OnPictogramChanged(this.entityAsset_Id);
        },
        async OnPictogramChanged(pNewValue){
            this.entityAsset_Id = pNewValue;

            if (this.showPictogram)
            {
                let asset = await this.$ceesar.backend.GetModelByIdAsync("asset", this.entityAsset_Id);
                this.entityPictogramUrl = this.$ceesar.backend.imagesPath + asset.type + "/" + asset.stored_file_name;
            }
        },

        CategoryLinkChanged(pId, pChecked){
            let link = this.categories.find(value => value.id == pId);
            link.checked = pChecked;
        },
        RoadUserLinkChanged(pId, pChecked){
            let link = this.roadUsers.find(value => value.id == pId);
            link.checked = pChecked;
        },
        InfrastructureLinkChanged(pId, pChecked){
            let link = this.infrastructures.find(value => value.id == pId);
            link.checked = pChecked;
        },
        OthersTrajectoryLinkChanged(pId, pChecked){
            let link = this.othersTrajectories.find(value => value.id == pId);
            link.checked = pChecked;
        },
        AvTrajectoryLinkChanged(pId, pChecked){
            let link = this.avTrajectories.find(value => value.id == pId);
            link.checked = pChecked;
        },
        SiteLinkChanged(pId, pChecked){
            let link = this.sites.find(value => value.id == pId);
            link.checked = pChecked;
        },

        async AddLinkCheck(pModel, pLinkIds = ""){
            let linkIds = [];
            if(pLinkIds != null)
                linkIds = pLinkIds.split(',');

            let values = await this.$ceesar.backend.GetModelAsync(pModel, "get_all");

            values.sort((a, b) => a.label.localeCompare(b.label));
            values.forEach(entry =>{
                let res = linkIds.find(value => value == entry.id);
                entry.checked = res != undefined;
            });
            return values;
        },
        CheckLinkTable(pLinkTable)
        {
            let ok = false;
            pLinkTable.forEach(entry =>{
                if (entry.checked == true) ok = true;
            });
            return ok;
        },
        GetLinkIds(pLinkTable)
        {
            let ids = "";

            pLinkTable.forEach(entry =>{
                if (entry.checked == true)
                {
                    if (ids != "") ids += ",";
                    ids += entry.id;
                }
            });

            return ids;
        },

        OnCancel()
        {
            this.$router.go(-1);
        },
        async OnSave()
        {   
            if(this.entityInternalId.trim() == "")
            {
                this.$ceesar.design.showToast("Internal ID is mandatory.", "error");
                return;
            }
            if(this.entityDescription.trim() == "")
            {
                this.$ceesar.design.showToast("Description is mandatory.", "error");
                return;
            }

            if (!this.CheckLinkTable(this.categories)){
                this.$ceesar.design.showToast("At least one Category is required.", "error");
                return;
            } 
            if (!this.CheckLinkTable(this.roadUsers)){
                this.$ceesar.design.showToast("At least one Road User is required.", "error");
                return;
            }
            if (!this.CheckLinkTable(this.infrastructures)){
                this.$ceesar.design.showToast("At least one Infrastructure is required.", "error");
                return;
            }
            if (!this.CheckLinkTable(this.othersTrajectories)){
                this.$ceesar.design.showToast("At least one Other User Trajectory is required.", "error");
                return;
            }
            if (!this.CheckLinkTable(this.avTrajectories)){
                this.$ceesar.design.showToast("At least one AV Trajectory is required.", "error");
                return;
            }

            let assetSaved = await this.SavePictogram();
            switch(assetSaved)
            {
                case "ok":
                    break
                case "empty":
                    this.$ceesar.design.showToast("Pictogram is mandatory.", "error");
                    return;
                case "error":
                    return;
            }
            
            if(this.id == undefined || this.id == "duplicate")
            {
                let entity = {
                    internal_id: this.entityInternalId, 
                    description: this.entityDescription,
                    asset_id: this.entityAsset_Id,

                    category_Ids: this.GetLinkIds(this.categories),
                    road_user_Ids: this.GetLinkIds(this.roadUsers),
                    infrastructure_Ids: this.GetLinkIds(this.infrastructures),
                    road_user_trajectory_Ids: this.GetLinkIds(this.othersTrajectories),
                    av_trajectory_Ids: this.GetLinkIds(this.avTrajectories),
                    site_Ids: this.GetLinkIds(this.sites)
                };

                let res = await this.$ceesar.backend.PostModelAsync("scenario", entity);
                if(res.code < 0)
                {
                    this.$ceesar.design.showToast(res.message, "error");
                    return;
                }
            }
            else
            {
                let entity = {
                    id: this.id, 
                    internal_id: this.entityInternalId, 
                    description: this.entityDescription,
                    asset_id: this.entityAsset_Id,

                    category_Ids: this.GetLinkIds(this.categories),
                    road_user_Ids: this.GetLinkIds(this.roadUsers),
                    infrastructure_Ids: this.GetLinkIds(this.infrastructures),
                    road_user_trajectory_Ids: this.GetLinkIds(this.othersTrajectories),
                    av_trajectory_Ids: this.GetLinkIds(this.avTrajectories),
                    site_Ids: this.GetLinkIds(this.sites)
                };
                let res = await this.$ceesar.backend.PutModelAsync("scenario", entity);
                if(res.code < 0)
                {
                    this.$ceesar.design.showToast(res.message, "error");
                    return;
                }
            }

            this.$router.go(-1);
        },
        async SavePictogram()
        {
            if (this.entityAsset_Id > 0) return "ok";
            
            let input = document.getElementById("scenario_pictogram");
            if(input.files.length < 1) return "empty";
            
            // File Upload & asset creation
            let assetEntity = {
                type: 'scenario_full'
            }
            let res = await this.$ceesar.backend.PostModelAsync("asset", assetEntity, input.files[0]);
            if(res.code < 0)
            {
                this.$ceesar.design.showToast(res.message, "error");
                return "error";
            }

            this.entityAsset_Id = res.data.id;

            return "ok";
        },
        async OnPreview(){
            this.entityPreview = {
                internal_id: this.entityInternalId, 
                description: this.entityDescription,
                asset_id: this.entityAsset_Id,

                category_Ids: this.GetLinkIds(this.categories),
                road_user_Ids: this.GetLinkIds(this.roadUsers),
                infrastructure_Ids: this.GetLinkIds(this.infrastructures),
                road_user_trajectory_Ids: this.GetLinkIds(this.othersTrajectories),
                av_trajectory_Ids: this.GetLinkIds(this.avTrajectories)
            };

            this.$ceesar.design.showDialog("ScenarioPreview");
        }
    }

}
</script>


<style>

.Pictogram{
    margin: 20px;
    align-self: center;
}

.FileUpload-Pictogram{
    margin: 20px;
    align-self: center;
}

</style>