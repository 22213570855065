<template>
  <CEESAR-View title="Edition">
    
    <CEESAR-Table v-if="allFiltersAvailable">
      <template slot="Buttons">
        <CEESAR-Button label="Add new Scenario" icon="add_circle_outline" v-on:click="ShowNewFile()" />
      </template>
      <template slot="Filters">
        <CEESAR-TextInputBox label="Internal ID" v-model="filterInternalId" :horizontal="true"/>
        <CEESAR-TextInputBox label="Description" v-model="filterDescription" :horizontal="true"/>
        <CEESAR-ComboBox label="Category" :entries="filterCategories" :horizontal="true"
                         v-on:change="OnCategoryChanged" :value="filterCategory"/>
        <CEESAR-ComboBox label="Infrastructure" :entries="filterInfrastructures" :horizontal="true"
                         v-on:change="OnInfrastructureChanged" :value="filterInfrastructure"/>
        <CEESAR-ComboBox label="Road User" :entries="filterRoadUsers" :horizontal="true"
                         v-on:change="OnRoadUserChanged" :value="filterRoadUser"/>
        <CEESAR-ComboBox label="Others Trajectory" :entries="filterTrajectories" :horizontal="true"
                         v-on:change="OnTrajectoryOthersChanged" :value="filterTrajectory_Others"/>
        <CEESAR-ComboBox label="AV Trajectory" :entries="filterTrajectories" :horizontal="true"
                         v-on:change="OnTrajectoryAVChanged" :value="filterTrajectory_AV"/>
        <CEESAR-ComboBox label="Operation Site" :entries="filterSites" :horizontal="true"
                         v-on:change="OnSiteChanged" :value="filterSite"/>
      </template>

      <template slot="Tools">
        <CEESAR-ComboBox-DropDown id="SortButton" label="Sort by:" :entries="sortModes" :horizontal="true"
                                  v-on:change="OnSortModeChanged" :value="selectedSortModeId"/>
      </template>

      <CEESAR-TableLine v-for="entity in filteredEntities" :key="entity.id" v-on:click="ShowFile(entity.id)">
        <div>
          {{entity.internal_id}} - {{entity.description}}
        </div>

        <template slot="Buttons">
          <CEESAR-Button label="Duplicate" icon="control_point_duplicate" v-on:click="DuplicateFile(entity)"/>
          <CEESAR-Button label="Delete" icon="delete" v-on:click="AskFileDeletionConfirmation(entity.id)"/>
        </template>
      </CEESAR-TableLine>
    </CEESAR-Table>

    <CEESAR-MessageLabel format="Warning" v-if="!allFiltersAvailable">
      Scenario edition is unavailable: <br> At least one reference table is empty.
    </CEESAR-MessageLabel>

  </CEESAR-View>
</template>

<script>
export default {
  name:"ScenarioTable",
  data(){
    return{
      entities: [],
      
      filterInternalId: "",
      filterDescription: "",
      filterCategories: [], filterCategory: 0,
      filterInfrastructures: [], filterInfrastructure: 0,
      filterRoadUsers: [], filterRoadUser: 0,
      filterTrajectories: [], filterTrajectory_Others: 0, filterTrajectory_AV: 0,
      filterSites: [], filterSite: 0,

      allFiltersAvailable: true,

      sortModes: [
        {id: 0, label: "<None>"},
        {id: 1, label: "Internal ID, ascending"},
        {id: 2, label: "Internal ID, descending"}, 
        {id: 3, label: "Description, ascending"},
        {id: 4, label: "Description, descending"}],
      selectedSortModeId: 0
    };
  },
  async mounted(){
    await this.LoadEntities();
    await this.LoadFilterCombos();
  },
  computed:{
    filteredEntities()
    {
      if(this.entities == null) return null;
      let filtered = this.entities.filter(e => {
        if(this.filterInternalId != "" && !e.internal_id.toUpperCase().includes(this.filterInternalId.toUpperCase()))
          return false;
        
        if(this.filterDescription != "" && !e.description.toUpperCase().includes(this.filterDescription.toUpperCase()))
          return false;

        if (!this.CheckFilterCombo(e.category_Ids, this.filterCategory)) return false;
        if (!this.CheckFilterCombo(e.infrastructure_Ids, this.filterInfrastructure)) return false;
        if (!this.CheckFilterCombo(e.road_user_Ids, this.filterRoadUser)) return false;
        if (!this.CheckFilterCombo(e.road_user_trajectory_Ids, this.filterTrajectory_Others)) return false;
        if (!this.CheckFilterCombo(e.av_trajectory_Ids, this.filterTrajectory_AV)) return false;
        if (!this.CheckFilterCombo(e.site_Ids, this.filterSite)) return false;

        return true;
      });

      if(this.selectedSortModeId > 0)
      {
        filtered = filtered.sort((a, b) => {
          switch(this.selectedSortModeId)
          {
            case 1:
              return a.internal_id < b.internal_id? -1 : 1;
            case 2:
              return a.internal_id > b.internal_id? -1 : 1;
            case 3:
              return a.description < b.description? -1 : 1;
            case 4:
              return a.description > b.description? -1 : 1;
          }
          return 0;
        }); 
      }

      return filtered;
    }
  },
  methods:{
    async LoadEntities(){
      this.entities = await this.$ceesar.backend.GetModelAsync("scenario", "get_all");
    },

    OnSortModeChanged(pValue){
      this.selectedSortModeId = pValue;
    },

    ShowFile(pId){
      this.$router.push({ path: "/scenarios/edition/file/" + pId});
    },
    ShowNewFile(){
      this.$router.push("/scenarios/edition/file");
    },
    
    async AskFileDeletionConfirmation(pId)
    {
      this.$ceesar.design.registerDialogEvent("Answer", (pAnswer) => this.confirmFileDeletion(pAnswer, pId));

      this.$ceesar.design.showConfirm(
        "This Scenario is about to be deleted. Do you want to continue?",
        [
          {label: "Confirm", value: true, icon: "check"},
          {label: "Cancel", value: false, icon: "cancel"},
        ]
      );
    },
    async confirmFileDeletion(pDialogAnswer, pId)
    {
      this.$ceesar.design.unregisterDialogEvent("Answer");

      if(!pDialogAnswer) return;

      let res = await this.$ceesar.backend.DeleteModelAsync("scenario", pId);
      if(res.code < 0)
      {
          this.$ceesar.eventHub.$emit("ShowToast", res.message, "error");
          return;
      }
      await this.LoadEntities();
    },


    DuplicateFile(pEntity){
      this.$ceesar.setTransmitData("scenario", pEntity);
      this.$router.push({ path: "/scenarios/edition/file/duplicate"});
    },

    async LoadFilterCombos(){
      this.filterCategories = await this.LoadFilterCombo("category");
      this.filterInfrastructures = await this.LoadFilterCombo("infrastructure");
      this.filterRoadUsers = await this.LoadFilterCombo("road_user");
      this.filterTrajectories = await this.LoadFilterCombo("trajectory");
      this.filterSites = await this.LoadFilterCombo("site");
    },
    async LoadFilterCombo(pModel){
      let unavailable = [];
      unavailable.push({id: -1, label: "<Unavailable>"});

      let entities = await this.$ceesar.backend.GetModelAsync(pModel, "get_all");
      if (entities == null || entities.length < 1)
      {
        this.allFiltersAvailable  = false;
        return unavailable;
      }

      let items = [];
      items.push({id: 0, label: "All"});

      entities.sort((a, b) => a.label.localeCompare(b.label));
      entities.forEach(entity =>{
          items.push({id: entity.id, label: entity.label});
      });

      return items;
    },

    OnCategoryChanged(pValue){
      this.filterCategory = pValue
    },
    OnInfrastructureChanged(pValue){
      this.filterInfrastructure = pValue
    },
    OnRoadUserChanged(pValue){
      this.filterRoadUser = pValue
    },
    OnTrajectoryOthersChanged(pValue){
      this.filterTrajectory_Others = pValue
    },
    OnTrajectoryAVChanged(pValue){
      this.filterTrajectory_AV = pValue
    },
    OnSiteChanged(pValue){
      this.filterSite = pValue
    },

    CheckFilterCombo(pLinkIds, pSelectedValue){
      if (pSelectedValue < 1) return true;
      if (pLinkIds == null) return false;

      let linkIds = pLinkIds.split(',');
      return linkIds.includes(pSelectedValue.toString());
    }
  }
}
</script>

<style lang="less">
#SortButton{
  width: 320px;
}
</style>