<template>
  <CEESAR-View title="Tables">
    <template slot="Menu">
      <CEESAR-MenuItem label="Categories" icon="category" route="/tables/categories"/>
      <CEESAR-MenuItem label="Infrastructures" icon="edit_road" route="/tables/infrastructures"/>
      <CEESAR-MenuItem label="Road Users" icon="people_alt" route="/tables/roadusers"/>
      <CEESAR-MenuItem label="Trajectories" icon="call_split" route = "/tables/trajectories"/>
      <CEESAR-MenuItem label="Sites" icon="map" route = "/tables/sites"/>
    </template>

    <transition name="TableRouter">
      <router-view />
    </transition>

  </CEESAR-View>
</template>

<script>
export default {
  name: "Tables"
}
</script>

<style>

</style>