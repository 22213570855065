import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Scenarios from '../views/Scenarios.vue';
  import ScenarioTable from '../views/ScenarioTable.vue';
    import ScenarioFile from '../views/ScenarioFile.vue'
  import ScenarioRanking from '../views/ScenarioRanking.vue'
  import ScenarioMatches from '../views/ScenarioMatches.vue'

import Tables from '../views/Tables.vue';
import CategoryTable from '../views/CategoryTable.vue';
  import CategoryFile from '../views/CategoryFile.vue'
import InfrastructureTable from '../views/InfrastructureTable.vue';
  import InfrastructureFile from '../views/InfrastructureFile.vue';
import RoadUserTable from '../views/RoadUserTable.vue';
  import RoadUserFile from '../views/RoadUserFile.vue';
import TrajectoryTable from '../views/TrajectoryTable.vue';
  import TrajectoryFile from '../views/TrajectoryFile.vue';
import SiteTable from '../views/SiteTable.vue';
  import SiteFile from '../views/SiteFile.vue';
import Assets from '../views/Assets.vue';

const routes = [
  {
    path: '/',
    redirect: '/scenarios'
  },
  {
    path: '/scenarios',
    name: 'Scenarios',
    component: Scenarios,
    redirect: '/scenarios/edition',
    children:[
      {
        path: 'edition',
        name: 'ScenarioTable',
        component: ScenarioTable
      },
        {
          path: 'edition/file',
          name: 'ScenarioFileNew',
          component: ScenarioFile
        },
        {
          path: 'edition/file/:id',
          name: 'ScenarioFile',
          component: ScenarioFile,
          props: true
        },
      {
        path: 'ranking',
        name: 'ScenarioRanking',
        component: ScenarioRanking
      },
      {
        path: 'matches',
        name: 'ScenarioMatches',
        component: ScenarioMatches
      }
    ]
  },

  {
    path: '/tables',
    name: 'Tables',
    component: Tables,
    redirect: '/tables/categories',
    children:[
      {
        path: 'categories',
        name: 'CategoryTable',
        component: CategoryTable
      },
        {
          path: 'categories/file',
          name: 'CategoryFileNew',
          component: CategoryFile
        },
        {
          path: 'categories/file/:id',
          name: 'CategoryFile',
          component: CategoryFile,
          props: true
        },
      {
        path: 'infrastructures',
        name: 'InfrastructureTable',
        component: InfrastructureTable
      },
        {
          path: 'infrastructures/file',
          name: 'InfrastructureNew',
          component: InfrastructureFile
        },
        {
          path: 'infrastructures/file/:id',
          name: 'Infrastructure',
          component: InfrastructureFile,
          props: true
        },
      {
        path: 'roadusers',
        name: 'RoadUserTable',
        component: RoadUserTable
      },
        {
          path: 'roadusers/file',
          name: 'RoadUserNew',
          component: RoadUserFile
        },
        {
          path: 'roadusers/file/:id',
          name: 'RoadUser',
          component: RoadUserFile,
          props: true
        },
      {
        path: 'trajectories',
        name: 'TrajectoryTable',
        component: TrajectoryTable
      },
        {
          path: 'trajectories/file',
          name: 'TrajectoryNew',
          component: TrajectoryFile
        },
        {
          path: 'trajectories/file/:id',
          name: 'Trajectory',
          component: TrajectoryFile,
          props: true
        },
      {
        path: 'sites',
        name: 'SiteTable',
        component: SiteTable
      },
        {
          path: 'sites/file',
          name: 'SiteNew',
          component: SiteFile
        },
        {
          path: 'sites/file/:id',
          name: 'Sites',
          component: SiteFile,
          props: true
        },
    ]
  },
    
  {
    path: '/assets',
    name: 'Assets',
    component: Assets
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
