<template>
  <CEESAR-ModelTable 
    title = "Trajectories"
    label = "Trajectory"
    model = "trajectory"
    fileRoute = "trajectories/file"
  />
</template>

<script>
export default {
  name:"TrajectoryTable"
}
</script>