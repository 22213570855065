<template>
    <CEESAR-View title="Assets">

        <CEESAR-Table>
            <template slot="Buttons">
              <!--
                <CEESAR-Button label="Add new Scenario"  v-on:click="ShowNewFile()" />
              -->
            </template>

            <template slot="Filters">
                <CEESAR-TextInputBox label="File Name" v-model="filterFileName" :horizontal="true"/>
                <CEESAR-ComboBox label="Type" :entries="filterTypes" :horizontal="true"
                                 v-on:change="OnTypeChanged" :value="filterType"/>
            </template>

            <div class="PictogramTableContent">
                <div class="PictogramLine" v-for="entity in filteredEntities" :key="entity.id">
                    <div v-on:click="ShowFile(entity.id)">
                        <img :src="$ceesar.backend.imagesPath + entity.type + '/' + entity.stored_file_name" />
                        {{entity.file_name}}
                    </div>

                    <template slot="Buttons_">
                        <CEESAR-Button label="Delete" v-on:click="AskFileDeletionConfirmation(entity.id)"/>
                    </template>
                </div>
            </div>
           
        </CEESAR-Table>

    </CEESAR-View>
</template>

<script>
export default {
  name:"Scenarios",
  data(){
    return{
      entities: [],
      
      filterFileName: "",
      filterTypes: [], filterType: 0
    };
  },
  async mounted(){
    await this.LoadEntities();
    await this.LoadFilterCombos();
  },
  computed:{
    filteredEntities()
    {
      if(this.entities == null) return null;
      return this.entities.filter(e => {
        if(this.filterFileName != "" && !e.file_name.toUpperCase().includes(this.filterFileName.toUpperCase()))
          return false;

        // if (!this.CheckFilterCombo(e.category_Ids, this.filterCategory)) return false;
        
        return true;
      });
    }
  },
  methods:{
    async LoadEntities(){
      this.entities = await this.$ceesar.backend.GetModelAsync("asset", "get_all");
    },
    ShowFile(pId){
      this.$router.push({ path: "/asset/" + pId});
    },
    ShowNewFile(){
      this.$router.push("/asset");
    },
    
    async AskFileDeletionConfirmation(pId)
    {
      this.$ceesar.design.registerDialogEvent("Answer", (pAnswer) => this.confirmFileDeletion(pAnswer, pId));

      this.$ceesar.design.showConfirm(
        "This Asset is about to be deleted. Do you want to continue?",
        [
          {label: "Confirm", value: true},
          {label: "Cancel", value: false},
        ]
      );
    },
    async confirmFileDeletion(pDialogAnswer, pId)
    {
      this.$ceesar.design.unregisterDialogEvent("Answer");

      if(!pDialogAnswer) return;

      let res = await this.$ceesar.backend.DeleteModelAsync("asset", pId);
      if(res.code < 0)
      {
          this.$ceesar.eventHub.$emit("ShowToast", res.message, "error");
          return;
      }
      await this.LoadEntities();
    },
    async LoadFilterCombos(){
      this.filterTypes = [];
    },

    OnTypeChanged(pValue){
      this.filterType = pValue
    }
  }
}
</script>

<style lang="less">
    .PictogramTableContent{
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .PictogramLine{
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin: 5px;
        cursor: pointer;
    }
</style>