<template>
    <CEESAR-View title="Matches">
      <CEESAR-Table>
        <template slot="Filters">
          <CEESAR-ComboBox label="Site" :entries="filterSites" :horizontal="true"
                         v-on:change="OnSiteChanged" :value="filterSite"/>
        </template>

        <CEESAR-TableLine v-for="entity in entities" :key="entity.id">
          <div class="line-Match">
            <div class="line-Match-User">
              <strong>{{entity.user_name}}</strong>, on {{entity.timestamp}}:
            </div>
            <div class="line-Match-Scenario">
              <span class="line-Match-Winner" v-if="entity.result == 1">
                {{entity.internal_id_1}} - {{entity.description_1}}
              </span>
              <span class="line-Match-Draw" v-if="entity.result == 0">
                {{entity.internal_id_1}} - {{entity.description_1}}
              </span>
              <span v-if="entity.result == 1">
                won against
              </span>
              <span v-if="entity.result == 0">
                and
              </span>

              <span class="line-Match-Loser" v-if="entity.result == 1">
                {{entity.internal_id_2}} - {{entity.description_2}}
              </span>
              <span class="line-Match-Draw" v-if="entity.result == 0">
                {{entity.internal_id_2}} - {{entity.description_2}}
              </span>

              <span v-if="entity.result == 0">
                are tied
              </span>
            </div>
          </div>
        </CEESAR-TableLine>
      </CEESAR-Table>
    </CEESAR-View>
</template>

<script>
export default {
  name:"ScenariosMatches",
  data(){
    return{
      entities: [],
      
      filterSites: [], filterSite: 0
    };
  },
  async mounted(){
    await this.LoadFilterCombos();
    await this.LoadEntities();
  },
  methods:{
    async LoadEntities(){
      this.entities = await this.$ceesar.backend.GetModelAsync("scenario", "get_matches", {site_id: this.filterSite});
    },
    async LoadFilterCombos(){
      this.filterSites = await this.LoadFilterCombo("site");
      this.filterSite = this.filterSites[0].id;
    },
    async LoadFilterCombo(pModel){
      let unavailable = [];
      unavailable.push({id: -1, label: "<Unavailable>"});

      let entities = await this.$ceesar.backend.GetModelAsync(pModel, "get_all");
      if (entities == null || entities.length < 1)
      {
        this.allFiltersAvailable  = false;
        return unavailable;
      }

      let items = [];
      entities.sort((a, b) => a.label.localeCompare(b.label));
      entities.forEach(entity =>{
          items.push({id: entity.id, label: entity.label});
      });

      return items;
    },

    async OnSiteChanged(pValue){
      this.filterSite = pValue,
      this.LoadEntities();
    }
  }
}
</script>

<style lang="less">
@color-blue: rgb(25, 75, 104);
@color-green: rgb(70, 173, 70);
@color-winner: lighten(@color-green, 15%);
@color-loser: darken(@color-green, 15%);


.line-Match{
  padding: 10px 15px;
  width: 100%;

  .line-Match-User{
    padding-bottom: 5px;
  }
  .line-Match-Scenario{
    margin-left: 15px;
    font-weight: normal;
    .line-Match-Winner{
      font-weight: bold;
      color: @color-winner;
    }
    .line-Match-Loser{
      font-weight: bold;
      color: @color-loser;
    }
    .line-Match-Draw{
      font-weight: bold;
      color: @color-green;
    }
    padding-bottom: 5px;
  }
}


</style>