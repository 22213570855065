import Vue from 'vue';
import axios from 'axios';

class CeesarBackend
{
    #path;
    #assetPath;
    get assetPath(){ return this.#path + this.#assetPath; }
    get imagesPath(){ return this.assetPath + "images/" ; }

    #headers;
    #fileHeaders;

    #axios;
    get axios(){ return this.#axios; }

    #token = "token";
    setKeyCloakToken(pToken)
    {
        this.#token = pToken;
    }
    currentUser = "";

    // Meta
    get name(){
        return "CEESAR-Backend";
    }
    get description()
    {
        return "CEESAR AXIOS backend framework";
    }

    install(pVue, pOptions) 
    {
        pVue.prototype.$ceesar.backend = this;
    }

    // Model Queries: GET
    async GetModelAsync(pModel, pAction, pFilter)
    {
        let body = {
            model: pModel,
            action: pAction,
            filter: pFilter
        };

        let res = await this.GetAsync("api/model.php", body);
        if (res.code < 1) return null;
        return res.data;
    }
    async GetModelByIdAsync(pModel, pId)
    {
        let data = JSON.stringify({id: pId});
        let filter = JSON.stringify({where: "id = :id", data: data });

        let body = {
            model: pModel,
            action: "get_where",
            filter : filter
        };

        let res = await this.GetAsync("api/model.php", body);
        if (res.code < 1) return null;
        if (res.data == null || res.data.length < 1) return null;
        return res.data[0];
    }

    // Model Queries: POST
    async PostModelAsync(pModel, pEntity, pFile = null)
    {
        if (pFile == null)
        {
            let body = {
                model: pModel,
                data: pEntity
            };

            let str = JSON.stringify(body);
            return await this.PostAsync("api/model.php", str);
        }
        else
        {
            let formData = new FormData();
            formData.append('file', pFile);
            formData.append('model', pModel);
            formData.append('data', JSON.stringify(pEntity));

            return await this.PostFormAsync("api/model.php", formData);
        }
    }
    // Model Queries: PUT
    async PutModelAsync(pModel, pEntity)
    {
        let body = {
            model: pModel,
            data: pEntity
        };
        let str = JSON.stringify(body);
        return await this.PutAsync("api/model.php", str);
    }
    // Model Queries: DELETE
    async DeleteModelAsync(pModel, pId)
    {
        let data = {id: pId};
        let filter = {where: "id = :id", data: data };

        let body = {
            model: pModel,
            filter: filter
        };

        return await this.DeleteAsync("api/model.php", body);
    }


    // Queries
    async GetAsync(pPath, pBody)
    {
        let config = {
            params: pBody
        }
        
        try{
            var res = [];
            await this.#axios.get(pPath, config).then(a => res = a);
            return res.data;
        }
        catch (error)
        {
            return {
                code: -1,
                message: error,
                data: null
            }
        }
    }

    async PostAsync(pPath, pBody)
    {
        try{
            var res = [];
            await this.#axios.post(pPath, pBody).then(a => res = a);
            return res.data;
        }
        catch (error)
        {
            return {
                code: -1,
                message: error,
                data: null
            }
        }
    }

    async PostFormAsync(pPath, pFormData)
    {
        try{
            let formAxios = axios.create({
                baseURL: this.#path,
                headers: this.#fileHeaders
            });
            
            var res = [];
            await formAxios.post(pPath, pFormData).then(a => res = a);
            return res.data;
        }
        catch (error)
        {
            return {
                code: -1,
                message: error,
                data: null
            }
        }
    }


    async PutAsync(pPath, pBody)
    {
        var res = [];
        try{
            await this.#axios.put(pPath, pBody).then(a => res = a);
            return res.data;
        }
        catch (error)
        {
            return {
                code: -1,
                message: error,
                data: null
            }
        }
    }

    async DeleteAsync(pPath, pBody)
    {
        let config = {
            data: pBody
        }

        var res = [];
        try{
            await this.#axios.delete(pPath, config).then(a => res = a);
            return res.data;
        }
        catch (error)
        {
            return {
                code: -1,
                message: error,
                data: null
            }
        }
    }

    constructor(pSetup)
    {
        this.#path = pSetup.hostPath;
        this.#headers = {
            'Content-type': 'application/json',
            'client-security-token': this.#token
        };

        this.#fileHeaders = {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'client-security-token': this.#token
        }

        this.#axios = axios.create({
            baseURL: this.#path,
            headers: this.#headers
        });

        this.#assetPath = pSetup.assetPath;
    }
}

export { CeesarBackend };