import Vue from 'vue'

class ceesarScenarioDesign
{
    // Meta
    get name(){
        return "CEESAR-Scenario";
    }
    get description()
    {
        return "CEESAR vue.js Scenario component framework";
    }

    install(pVue, pOptions) 
    {
        Vue.component("CEESAR-ScenarioCardList", () => import("./components/ScenarioCardList.vue"));
        Vue.component("CEESAR-ScenarioCard", () => import("./components/ScenarioCard.vue"));

        pVue.prototype.$ceesar.scenarioDesign = this;
    }
}

export default new ceesarScenarioDesign();